import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import AddInfo from '@/views/AddInfo.vue';

// 定义路由的类型
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/add',
    name: 'AddInfo',
    component: AddInfo
  },
  // ...其他路由
];

// 创建路由实例并传入 `routes` 配置
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // 使用 HTML5 的历史记录模式
  routes // `routes: routes` 的缩写
});

export default router;
