<template>
  <div class="home">
    <h1>欢迎来到首页</h1>
    <!-- 首页的其他内容可以放在这里 -->
  </div>
</template>

<script lang="ts">
export default {
  name: 'HomePage', // 组件的名称
  // 组件的选项，如 data, methods 等
}
</script>

<style scoped>
.home {
  /* 首页的样式 */
  text-align: center;
  margin-top: 50px;
}
</style>
