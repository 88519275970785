<template>
  <el-row>
    <el-divider>个人信息</el-divider>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>姓名*：</span>
    </el-col>
    <el-col :span="16">
      <el-input
        v-model.trim="formData.username"
        style="width: 240px"
        placeholder="请输入姓名"
      />
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>性别*：</span>
    </el-col>
    <el-col :span="16">
      <el-radio-group
        v-model="formData.sex"
        size="large"
      >
        <el-radio-button
          label="男"
          value="男"
        />
        <el-radio-button
          label="女"
          value="女"
        />
      </el-radio-group>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>电话*：</span>
    </el-col>
    <el-col :span="16">
      <el-input
        v-model.trim="formData.phone"
        style="width: 240px"
        placeholder="请输入电话"
      />
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>婚姻状况*：</span>
    </el-col>
    <el-col :span="16">
      <el-radio-group
        v-model="formData.maritalStatus"
        size="large"
      >
        <el-radio-button
          label="未婚"
          value="未婚"
        />
        <el-radio-button
          label="已婚"
          value="已婚"
        />
        <el-radio-button
          label="离异"
          value="离异"
        />
      </el-radio-group>
    </el-col>
  </el-row>
  <el-row>
    <el-divider>房产信息</el-divider>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>小区名称*：</span>
    </el-col>
    <el-col :span="16">
      <el-input
        v-model.trim="formData.housename"
        style="width: 240px"
        placeholder="请输入小区名称"
      />
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>房产面积*：</span>
    </el-col>
    <el-col :span="5">
      <el-input-number
        v-model="formData.housearea"
        placeholder=""
      />
    </el-col>
    <el-col :span="11">
      <span>平方</span>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>房产价值*：</span>
    </el-col>
    <el-col :span="5">
      <el-input-number
        v-model="formData.housevalue"
        placeholder=""
      />
    </el-col>
    <el-col :span="11">
      <span>万元</span>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col>
      <span>（房产价值参考贝壳房价）</span>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>房产位置*：</span>
    </el-col>
    <el-col :span="16">
      <el-input
        v-model.trim="formData.houselocation"
        style="width: 240px"
        :rows="2"
        type="textarea"
        placeholder="请输入房产位置"
      />
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>客户来源*：</span>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col :span="24">
      <el-radio-group
        v-model="formData.userSource"
        size="large"
      >
        <el-radio-button
          label="新房装修"
          value="新房装修"
        />
        <el-radio-button
          label="二手房"
          value="二手房"
        />
        <el-radio-button
          label="房子改造"
          value="房子改造"
        />
        <el-radio-button
          label="其他"
          value="其他"
        />
      </el-radio-group>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>其他来源：</span>
    </el-col>
    <el-col :span="16">
      <el-input
        v-model.trim="formData.otherSource"
        style="width: 240px"
        placeholder="请输入其他来源说明"
      />
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>其他资产：</span>
    </el-col>
    <el-col :span="16">
      <el-input
        v-model.trim="formData.otherproperty"
        style="width: 240px"
        :rows="2"
        type="textarea"
        placeholder="请输入其他资产"
      />
    </el-col>
  </el-row>
  <el-row>
    <el-divider>负债及收入信息*</el-divider>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>总负债金额*：</span>
    </el-col>
    <el-col :span="12">
      <el-input-number v-model="formData.totalliabilities" />
    </el-col>
    <el-col :span="4">
      <span>元</span>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>信用贷款*：</span>
    </el-col>
    <el-col :span="12">
      <el-input-number v-model="formData.creditloans" />
    </el-col>
    <el-col :span="4">
      <span>元</span>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="10"
      class="label-col"
    >
      <span>近6个月使用额度*：</span>
    </el-col>
    <el-col :span="10">
      <el-input-number v-model="formData.usedlimit" />
    </el-col>
    <el-col :span="4">
      <span>元</span>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>工作单位*：</span>
    </el-col>
    <el-col :span="16">
      <el-input
        v-model.trim="formData.company"
        style="width: 240px"
        placeholder="请输入工作单位"
      />
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col
      :span="8"
      class="label-col"
    >
      <span>收入*：</span>
    </el-col>
    <el-col :span="12">
      <el-input-number v-model="formData.income" />
    </el-col>
    <el-col :span="4">
      <span>元</span>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col :span="24">
      <el-checkbox-group v-model="formData.checkList">
        <el-checkbox
          label="普通工作"
          value="普通工作"
        />
        <el-checkbox
          label="社保"
          value="社保"
        />
        <el-checkbox
          label="公积"
          value="公积"
        />
      </el-checkbox-group>
    </el-col>
  </el-row>
  <el-row
    :gutter="20"
    align="middle"
  >
    <el-col :span="24">
      <el-button
        type="primary"
        round
        @click="submitForm"
      >
        提交信息
      </el-button>
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
	import { ref, onMounted, computed } from 'vue'
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { ElMessageBox,ElMessage } from 'element-plus';
	
	interface FormData {
		id: number;
		yuangongid: number;
		sex: string;
		maritalStatus: string;
		phone: string;
		housename: string;
		housearea: number;
		houselocation: string;
		housevalue: number;
		creditloans: number;
		totalliabilities: number;
		usedlimit: number;
		otherproperty: string;
		userSource: string;
		otherSource: string;
		checkList: string[];
		username: string;
		company: string;
		income: number;
		openid: string;
	}
	// 初始化表单数据对象
	const formData = ref<FormData>({
		id: 0,
		yuangongid: 0,
		sex: '',
		maritalStatus: '',
		phone: '',
		housename: '',
		housearea: 0,
		houselocation: '',
		housevalue: 0,
		creditloans: 0,
		totalliabilities: 0,
		usedlimit: 0,
		otherproperty: '',
		userSource: '',
		otherSource: '',
		checkList: [],
		username: '',
		company: '',
		income: 0,
		openid: ''
	})
	



	const rules = {
		username: { required },
		sex: { required },
		maritalStatus: { required },
		phone: {
			required,
			phoneFormat: helpers.regex(/^1[3-9]\d{9}$/)
		},
		housename: { required },
		housearea: { required },
		houselocation: { required },
		housevalue: { required },
		creditloans: { required },
		totalliabilities: { required },
		usedlimit: { required },
		company: { required },
		income: { required },
		otherSource: {
			required: computed(() => formData.value.userSource === '其他')
		},
		// otherproperty 是选填的，不添加规则
	}

	const v = useVuelidate(rules, formData)

	// 模拟获取OpenID的函数
	const getOpenId = async () => {
		// 假设通过微信接口获取OpenID，实际应用中应调用微信接口
		// 这里直接返回一个模拟的OpenID
		const queryParams = new URLSearchParams(window.location.search)
		const code = queryParams.get('code')
		if (!code) {
			// Redirect to WeChat OAuth2 page for authorization if code is not present
			const appId = 'wx9300b57ad8b539df'
			const redirectUri = encodeURIComponent(window.location.href)
			const state = 'STATE' // You can add a custom state here for security
			window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
		} else {
			// Use the `code` to request `openid` from your server
			try {
				const response = await fetch(`/api/Info/getOpenID?code=${code}`)
				const data = await response.json()
				formData.value.openid = data.openid
				// Store openid in local storage for later use
				localStorage.setItem('openid', data.openid)
				return formData.value.openid
			} catch (error) {
				console.error('Error fetching openid:', error)
				return null // 或者其他合适的错误处理方式
			}
		}
	}


	// 模拟获取订单数据的函数
	const fetchOrderData = async (openid : string) => {
		// 调用后台API获取订单数据
		const response = await fetch(`/api/Info/getInfo?openid=${openid}`)
		if (response.ok) {
			const data = await response.json();  // 自动处理 JSON 字符串的 Unicode 转义
			// 如果数据中有字段仍然是 JSON 字符串，需要再次解析
			if (data.checkList && typeof data.checkList === 'string') {
				data.checkList = JSON.parse(data.checkList);  // 再次解析以恢复数组或对象
			}

			return data;
		} else {
			console.error('Failed to fetch order data', response.status)
			return null
		}
	}

	// 提交表单的函数
	const submitForm = async () => {
		v.value.$touch()
		if (!v.value.$error) {			
			// 执行提交逻辑
			// 检查订单id是否为0，判断是新建还是更新
			if (formData.value.id === 0) {
				// 新建订单
				await saveOrder()
			} else {
				// 确认是否更新订单信息
				const confirmed = confirm('确认要修改订单信息吗？')
				if (confirmed) {
					await saveOrder()
				}
			}
		} else {
			console.log('Invalid Form', v.value.$errors)
			let errorMessage = '请检查并填写所有必填项：\n';
			// Iterate over each validation and append the error message
			if (v.value.$params) {
				Object.keys(v.value.$params).forEach(key => {
					if (v.value[key] && v.value[key].$invalid) {
						errorMessage += `${key} 是必填项。\n`;
					}
				});
			}
			if (v.value.phone && v.value.phone.$invalid) {
				errorMessage += '电话号码格式不正确。\n'; // Special message for phone validation
			}
			ElMessageBox.alert(errorMessage, '表单错误', {
				confirmButtonText: '确定',
				type: 'error'
			});
		}
	}

	// 保存订单的函数
	const saveOrder = async () => {
		try {
			const response = await fetch('/api2/add_info', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData.value)
			})

			if (response.ok) {
				//nconst result = await response.json()
				ElMessageBox.alert('保存成功！', '成功', {
					confirmButtonText: '确定',
					type: 'success',
				});
			} else {
				const result = await response.json();
				ElMessage({
					message: `保存失败：${result.error || '未知错误'}`,
					type: 'error',
				});
			}
		} catch (error) {
			ElMessage({
				message: '出现异常',
				type: 'error',
			});
		}
	}

	// onMounted 生命周期钩子函数，组件加载时执行
	onMounted(async () => {
		document.title = '信息收集';
		const queryParams = new URLSearchParams(window.location.search);
		const yuangongid = queryParams.get('yuangongid');
		if (yuangongid) {
			const parsedYuangongid = parseInt(yuangongid, 10);
			// 检查parsedYuangongid是否是一个有效的整数
			if (!isNaN(parsedYuangongid)) {
				formData.value.yuangongid = parsedYuangongid;
			} else {
				formData.value.yuangongid = 0;
			}
		}
		let openid = localStorage.getItem('openid') || ''
		if (!openid) {
			openid = (await getOpenId()) || ''
			localStorage.setItem('openid', openid)
		} else {
			formData.value.openid = openid
		}

		// 获取订单数据
		const orderData = await fetchOrderData(openid)
		// 检查 orderData 是否为非空对象
		if (orderData && Object.keys(orderData).length > 0) {
			// 填充订单数据到表单
			Object.assign(formData.value, orderData)
			formData.value.id = orderData.id // 订单ID
			formData.value.yuangongid = orderData.yuangongid // 员工ID
		} else {
			// 没有订单数据时，订单ID设为0
			formData.value.id = 0
		}
	})
</script>

<script lang="ts">
	export default {
		name: 'AddInfo'
	}
</script>

<style scoped>
	.home {
		/* 首页的样式 */
		text-align: center;
		margin-top: 50px;
	}

	.el-row {
		margin: 20px;
	}

	.label-col {
		text-align: right;
		line-height: 36px;
		/* 确保标签垂直居中 */
	}

	span {
		color: gray;
	}

	.error-border {
		border-color: red;
	}

	.error-message {
		color: red;
		font-size: 12px;
		margin-top: 5px;
	}
</style>